<template>
  <a-card class="request-password-reset-form-card">
    <ValidationObserver ref="observer">
      <a-form
        :label-width="80"
        slot-scope="{ validate }"
        @submit.prevent="validate().then(handleSubmit)"
      >
        <h2 class="title">{{ labels.title }}</h2>
        <Input
          v-model="form.email"
          rules="required|email"
          vid="email"
          :placeholder="labels.form.email.placeholder"
          size="large"
          :allowClear="true"
          :label="labels.form.email.label"
        />
        <a-form-item style="text-align: right">
          <a-button
            type="default"
            html-type="submit"
            :loading="submitting"
            :disabled="submitting"
            size="large"
            class="btn-reset"
            @click.prevent="validate().then(handleSubmit)"
          >
            <span>{{ labels.form.submit }}</span>
          </a-button>
        </a-form-item>
        <a-form-item style="text-align: center">
          <a-button
            type="default"
            size="large"
            class="back-link"
            @click.prevent="handleBack"
            block
            ghost
          >
            {{ labels.form.goToLogin }}
          </a-button>
        </a-form-item>
      </a-form>
    </ValidationObserver>
  </a-card>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import _ from "lodash";
import Input from "@/components/core/VeeValidateForm/Input";
import labels from "@/utils/labels";
import { mapActions } from "vuex";
const initialState = {
  email: "",
};
export default {
  name: "RequestPasswordResetForm",
  components: {
    ValidationObserver,
    Input,
  },
  data() {
    return {
      form: _.cloneDeep(initialState),
      submitting: false,
      labels: labels.passwordResetForm,
    };
  },
  methods: {
    ...mapActions("clientAuth", ["handleRequestPasswordReset"]),
    async handleSubmit() {
      try {
        await this.handleRequestPasswordReset(this.form);
        this.$success({
          title: this.labels.form.success,
          centered: true,
          closable: false,
          maskClosable: false,
          keyboard: false,
          autoFocusButton: "ok",
          content: (
            <p class={"text-content"}>{this.labels.form.successMessage}</p>
          ),
          class: "success-modal",
          okText: this.labels.form.okText,
          onOk: () => {
            this.form = _.cloneDeep(initialState);
            this.$refs.observer.reset();
            this.$router.push({ name: "login" });
          },
          onCancel: () => {
            this.form = _.cloneDeep(initialState);
            this.$refs.observer.reset();
            this.$router.push({ name: "login" });
          },
        });
      } catch (error) {
        this.$error({
          title: this.labels.error,
          content:
            error?.response?.data?.message ||
            error?.response?.message ||
            error.message,
          centered: true,
        });
      } finally {
        this.submitting = false;
      }
    },
    handleBack() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/variable";
.request-password-reset-form-card {
  background-color: color(c-secondary);
  border-color: color(c-hover-light);
  box-shadow: 0 0 15px rgba(55, 245, 249, 35%);
  animation: shadowGlow 2s infinite ease;
  padding: 3.125rem;
  border-radius: 10px;
  @media screen and (max-width: $screen-mobile) {
    padding: 2.125rem 1rem;
  }
  .title {
    font-family: "Exo 2", sans-serif;
    color: color(--white);
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    word-break: break-word;
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
  }
  .ant-card-body {
    padding: 0;
  }
  .ant-form label {
    font-size: 0.875rem;
    color: color(--white);
    font-family: "Roboto", sans-serif;
  }
  .ant-input {
    background-color: color(c-primary);
    height: 3.75rem;
    color: color(--white);
    &:active,
    &:focus,
    &:hover {
      border-color: color(c-hover);
    }
  }
  .ant-input-affix-wrapper {
    .ant-input:not(:first-child) {
      padding-left: 2.8125rem;
    }
    .ant-input-prefix {
      color: color(--white);
      font-size: 1.375rem;
      font-family: "Roboto", sans-serif;
    }
    .ant-input-clear-icon,
    .ant-input-password-icon,
    .ant-input-textarea-clear-icon {
      color: color(--white);
    }
  }

  .has-error .ant-input-affix-wrapper .ant-input,
  .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: color(c-primary);
  }

  .btn-reset {
    color: color(c-primary) !important;
    background-color: color(c-hover);
    border-color: color(c-hover);
    height: 51px;
    padding: 0 1.75rem;
    width: 100% !important;
  }

  .back-link {
    @extend .btn-reset;
    color: color(c-hover) !important;
  }
}

input:-internal-autofill-selected {
  background-color: color(c-primary) !important;
  border-color: color(c-hover) !important;
  color: black !important;
}

// animation
@keyframes shadowGlow {
  0% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.15);
  }
  25% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.25);
  }
  50% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.35);
  }
  50% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.45);
  }
  100% {
    box-shadow: 0 0 15px rgba(55, 245, 249, 0.15);
  }
}
.success-modal {
  .ant-modal-content {
    background-color: color(c-secondary) !important;
    box-shadow: 0 1px 3px 3px color(c-hover-wait);
    .ant-modal-confirm-title {
      color: color(--white) !important;
    }
    .ant-modal-confirm-content {
      .text-content {
        color: color(--white) !important;
      }
    }
    .ant-modal-confirm-btns {
      .ant-btn {
        background-color: color(c-secondary);
        color: color(c-hover) !important;
        border-color: color(c-hover);
        &:hover,
        &:focus,
        &:active {
          background-color: color(c-secondary);
          color: color(c-hover) !important;
          border-color: color(c-hover);
        }
        &.ant-btn-primary {
          background-color: color(c-hover);
          color: color(c-primary) !important;
          border-color: color(c-hover);
          &:hover {
            background-color: color(c-hover);
            color: color(c-primary) !important;
            border-color: color(c-hover);
          }
        }
      }
    }
  }
}
</style>
